* {
    margin: 0;
    font-style: normal;
}

.background {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-image: url('./assets/bg.png');
    position: fixed;
    z-index: -1;
    background-color: #0F0D0E;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.4); /* IE */
  -moz-transform: scale(1.4); /* FF */
  -webkit-transform: scale(1.4); /* Safari and Chrome */
  -o-transform: scale(1.4); /* Opera */
  padding: 10px;
  border:1px solid gray
}

.fa-gear{
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}