.logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.container {
    min-height: 100vh;
    background-color: #0F0D0E;
    padding: 30px;
}

.leftSemiCircle {
    position: absolute;
    width: 58px;
    height: 57.89px;
    left: 2px;
    top: 532.38px;
    background: white;
    border-radius: 25px;
}

.rightSemiCircle {
    position: absolute;
    width: 58px;
    height: 57.89px;
    left: 334px;
    top: 532.38px;
    background: white;
    border-radius: 25px;
}

.statusCard {
    min-height: 520px;
    margin-top: 50px;
    height: 100%;
    width: 100%;
    background: #2A2C29;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.listView {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px 0px 20px;
}

.itemTitle {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.itemSubtitle {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px 10px 20px;
}

.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #BFBFBF;
    font-family: 'Inter-Regular';
    width: 40%;
}

.value {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 10px;
    font-family: 'Inter-Regular';
}

.token {
    position: absolute;
    width: 23.03px;
    height: 23.03px;
    background: #2A2C29;
    z-index: 1;
    border-radius: 10px;
}

.semiCircleOne {
    position: absolute;
    left: -15px;
    top: -11px;
}

.semiCircleTwo {
    position: absolute;
    right: -15px;
    top: -11px;
}

.semiCircleThree {
    position: absolute;
    left: -15px;
    bottom: -11px;
}

.semiCircleFour {
    position: absolute;
    right: -15px;
    bottom: -11px;
}

.tokenCard {
    position: relative;
    width: 196px;
    height: 78px;
    /* left: 97px;
    top: 521px; */
    background: red;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 8px;
}

.tokenTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

.subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.tokenText {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-family: 'Inter-Light';
    color: #BFBFBF;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dottedBorder {
    width: 90%;
    height: 0px;
    opacity: 0.5;
    border-top: 1px dashed #BFBFBF;
    margin: 30px 0px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.innerCircle1 {
    position: absolute;
    width: 58px;
    height: 57.89px;
    left: 2px;
    top: 522.38px;
    background: #0F0D0E;
    border-radius: 50%;
    z-index: 1;
}
.innerCircle2 {
    position: absolute;
    width: 58px;
    height: 57.89px;
    right: 2px;
    top: 522.38px;
    background: #0F0D0E;
    border-radius: 50%;
    z-index: 1;
}

.orderAgain {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top: 30px;
}