@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.container {
  display: flex;
  flex-direction: column;
  min-height: 101vh;
  /* height: 100%; */
  padding: 30px;
  background-color: #0f0d0e;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.logoContainer {
  margin-right: 120px;
}

.backArrow {
  font-size: 30px;
  margin-right: 20px;
  margin-right: 23px;
  width: 45px;
  height: 45px;
  background: rgba(217, 217, 217, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.header {
  font-family: "Inter-Light";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 70%;
  color: #ffffff;
}

.welcomeMessage {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  margin-top: 30px;
}
.buttonContainer {
  width: 100%;
  margin-top: 29px;
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 90%;
  height: 55px;
  background: #ff2c2b;
  border-radius: 5px;
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  border: none;
  color: #ffffff;
  margin-right: 60px;
  position: fixed;
  bottom: 20px;
}

.button:disabled {
  background-color: #bfbfbf;
}

.statusCard {
  width: 100%;
  background: #2a2c29;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
}

.cardPosition {
  margin-bottom: 55px;
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 10px 20px;
}

.label {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #bfbfbf;
  font-family: "Inter-Regular";
}

.value {
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  margin-right: 10px;
  font-family: "Inter-Regular";
}

.itemsLabel {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0px 20px;
  color: #bfbfbf;
}

.listLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 15px 20px;
}

.listContent {
  margin: 20px 0px 0px 0px;
}

.listTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 20px 20px;
}

.labelTotal {
  font-weight: 700;
}

.radio {
  background: #ffffff;
  border-radius: 12px;
}

.listType {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 15px 20px;
}

input[type="radio"] {
  accent-color: #ff2c2b;
}

span[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
