.logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.container {
    min-height: 100vh;
    padding: 30px;
    background-color: #0F0D0E;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-right: 11px;
}


.welcomeMessage {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin: 20px 0px 10px 0px;
}

.statusCard {
    width: 100%;
    height: 93px;
    background: #2A2C29;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 20px;
}

.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    font-family: 'Inter-Light';
}


.value {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    font-family: 'Inter-Regular';
}

.menuContainer {
    margin-top: 40px;
}

.cardContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
}

.cardImage {
    width: 35%;
    height: 129px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.itemName,
.itemPrice {
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    font-family: 'Inter-Regular';
}

.itemPrice {
    margin-top: 9px;
}

.cardDetails {
    width: 55%;
}

.itemSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 21px;
}

.selectorButton {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #2B2D2A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Inter';
    font-style: normal;
    color: #FFFFFF;
}

.itemDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #676766;
    margin-top: 30px;
    font-family: 'Inter-Light';

}

.descriptionOpen {
    overflow: auto;
    margin-right: 5px;
}

.descriptionClose {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.more {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FF2C2B;

}

.horizontalLine {
    width: 100%;
    /* opacity: 0.1; */
    border-top: 1px solid #ffffff;
    border-radius: 30px;
    margin-top: 30px;
}

.checkoutContainer {
    width: 100%;
    height: 280px;
    background: #FFFFFF;
    box-shadow: 0px -6px 18px rgba(61, 61, 61, 0.1);
    border-radius: 20px 20px 0px 0px;
    position: sticky;
    bottom: 0;
}

.checkoutContainerLess {
    width: 100%;
    height: 109px;
    background: #FFFFFF;
    box-shadow: 0px -6px 18px rgba(61, 61, 61, 0.1);
    border-radius: 20px 20px 0px 0px;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
}

.totalDetails {
    margin: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
}

.totalDetailsLess {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.totalItem {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #676766;
    font-family: 'Inter-Regular';
}

.totalPrice {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Inter-Regular';
    font-style: normal;
    color: #0F0D0E;
}

.menuType {
    height: 85px;
    background: #F2F2F2;
    border-radius: 10px;
    margin: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
}

.checkboxContainer {
    margin: 5px 16px;
}

.checkboxContainer label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #676766;
    margin-left: 15px;
}

.button {
    width: 85%;
    height: 55px;
    background: #FF2C2B;
    border-radius: 5px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border: none;
    color: #FFFFFF;
    margin: 0px 30px;
}

input[type=checkbox] {
    accent-color: #FF2C2B;
}

.more {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FF2C2B;
}

.ofcMore {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #686868;
}

.ofcBanner {
    width: 100%;
    height: 29px;
    left: 30px;
    top: 342px;
    font-family: 'Inter-Regular';
    font-style: normal;
    background: #FFFFFF;
    opacity: 0.8;
    border: 0.5px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close {
    position: absolute;
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 46%;
    top: -17px;
}

.ofs {
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Inter-Regular';
    font-style: normal;
    color: #FF2C2B;
}

.ofsContainer {
    position: absolute;
    width: 98%;
    height: 90%;
    background: #2A2C29;
    opacity: 0.34;
    scale: 1.1;
    border-radius: 10px;

}

.ofsImage {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}