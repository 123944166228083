.full_screen{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
}

.gif{
    width: 50px;
    height: 50px;
}