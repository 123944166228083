@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter/Inter-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter'), url(./fonts/Inter/Inter-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter'), url(./fonts/Inter/Inter-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Semibold';
  src: local('Inter'), url(./fonts/Inter/Inter-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter'), url(./fonts/Inter/Inter-Bold.ttf) format('opentype');
}