.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.welcomeMessage {
    text-align: center;
    font-family: "Inter";
    font-weight: 600;
    font-size: 37.4305px;
    line-height: 45px;
    color: #FFFFFF;
    margin-top: 25px;
}

.caption {
    width: 90%;
    text-align: center;
    font-family: 'Inter-Light';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #BFBFBF;
    margin-top: 10px;
}

.sub_caption {
    width: 305px;
    text-align: center;
    margin: 10px 0 20px;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #BFBFBF;
    margin-top: 32px;
}

.resendOtpActive{
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}
.resendOtpInActive{
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    margin-top: 20px;
    opacity: 0.5;
    text-decoration: underline;
}
.count{
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #BFBFBF;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    margin-top: 20px;
}




.button {
    width: 330px;
    height: 55px;
    background: #FF2C2B;
    border-radius: 5px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border: none;
    color: #FFFFFF;
    margin-top: 29px;
}

.resendBtn {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #FF2C2B;
    background-color: transparent;
    border: 0;
    margin-top: 15px;
}

.input{
    width: 300px;
    height: 50px;
    background: #2A2C29;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    padding: 0px 16px;
    border: none;
    color: #FFFFFF;
    margin-top: 20px;
    outline: none;
}

.button:disabled{
    background-color: #BFBFBF;
}