.loginContainer {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

}


.welcomeMessage {
    text-align: center;
    font-family: "Inter-Regular";
    font-weight: 600;
    font-size: 37.4305px;
    line-height: 45px;
    color: #FFFFFF;
    margin-top: 25px;
}

.caption {
    width: 90%;
    text-align: center;
    /* border-bottom: 1.5px solid #393939; */
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    ;
    margin-top: 36px;
    font-family: 'Inter-Light';
}
.notActive{
    width: 90%;
    text-align: center;
    margin: 10px 0 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: red;
    margin-top: 36px;
    font-family: 'Inter-Light';
}

.inActivePartner {
    margin-top: 35%;
}

.caption span {
    padding: 0 10px;
    background-color: #0F0D0E;
}

.name {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize;
    color: #FF2C2B;
    margin-top: 10px;
}

.button {
    width: 85%;
    height: 55px;
    background: #FF2C2B;
    border-radius: 5px;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    border: none;
    color: #FFFFFF;
    margin-top: 35px;
}

.logoContainer {
    margin-top: 23px;
    width: 174px;
    height: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/logo.png');
    background-repeat: no-repeat;
    background-position: center;
}

.circle1 {
    width: 308.9px;
    height: 308.9px;
    left: 39px;
    background: #0F0D0E;
    border: 1px solid #393939;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -72px;
    z-index: -1;
}

.circle2 {
    width: 263.74px;
    height: 263.74px;
    left: 61.58px;
    background: #0F0D0E;
    border: 1px solid #393939;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle3 {
    width: 220.77px;
    height: 220.77px;
    left: 83.06px;
    background: #0F0D0E;
    border: 1px solid #393939;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle4 {
    width: 204.47px;
    height: 204.47px;
    left: 91.21px;
    background: #FF2C2B;
    border: 1px solid #393939;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position:bottom;
    background-position: center;
background-size: cover;
background-repeat: no-repeat;
}